<template>
  <div class="kapsama">
    <div v-if="!loadingComplete" class="loading" style="margin-left: 50% ; margin-top: 15% ;margin-bottom: 10%">
      <svg height="48px" width="64px">
        <polyline id="back" points="0.157 23.954, 14 23.954, 21.843 48, 43 0, 50 24, 64 24"></polyline>
        <polyline id="front" points="0.157 23.954, 14 23.954, 21.843 48, 43 0, 50 24, 64 24"></polyline>
      </svg>
    </div>

    <div v-if="loadingComplete" class="container">
      <div class="row  mx-auto" style="width: 100% ;margin-bottom: 40px">

        <div v-if="sliderGallery !== null" class="col-lg-8 ">
          <!-- Slider Start -->
          <button
              class="topsliderright"
              @click="postGalleryNext"
          >
            <div class="arrow-icon ortalayıcı">
              <i class="fal fa-angle-right"></i>
            </div>

          </button>
          <button
              class="topsliderleft"
              @click="postGalleryPrev"
          >
            <div class="arrow-icon ortalayıcı">
              <i class="fal fa-angle-left"></i>
            </div>

          </button>
          <div v-if="sliderGallery.length !== 0" class="" style="">
            <slider ref="BigCards" :settings="SliderSettings">
              <template v-for="(single, index) in this.sliderGallery">
                <big-video-card-with-des :key="'slider' + index" :datas="single"/>
              </template>
            </slider>


          </div>


        </div>
        <div class="col-lg-4">
          <!-- Başkandan -->
          <div class="row  " style="align-items: start">

            <div class=" row  ">
              <button class="cheatbutton"
                      @click="clickHrefMethod('https://mvc.aeo.org.tr/UserFiles/files/0107202430092024_FRAEO.pdf')"

              >
                <span style="font-size: 15px;font-weight: bold;white-space: nowrap"> Ankara Eczacı Odası Yönetim Kurulu
</span>
                <br>
                <span style="font-size: 10px;"> 1 Temmuz - 30 Eylül Çalışma Raporu </span>
              </button>
              <!--              <img-->
              <!--                  :src="require(`@/assets/images/baskanfoto.png`)"-->
              <!--                  alt="trending"-->
              <!--                  height="140vh"-->
              <!--                  width="140vh"-->
              <!--              />-->
              <!--              <div class=" col-lg-12" style=" margin-left: 15vh; margin-top: -16vh; position: absolute">-->
              <!--                <br>-->
              <!--                <label style="font-weight: bold ;  font-size: 5vh">Yönetimden</label>-->
              <!--&lt;!&ndash;                <label style="white-space: nowrap; font-size: 22px"> Uzm.Ecz.C. Cem Abbasoğlu</label>&ndash;&gt;-->
              <!--&lt;!&ndash;                <label style="white-space: nowrap; font-size: 20px ; font-style: italic"> Yeni Döneme Merhaba</label>&ndash;&gt;-->

              <!--              </div>-->

            </div>


            <div class="row " style="align-items: start">
              <div class="col-lg-12 " style="margin-bottom: 10px;margin-top: 10px;margin-left: 4px ">
                <button style="border: 1px" @click="clickRouteMethod('/crisis-pha')">
                  <img
                      :src="require(`@/assets/images/eczane_logo_gif.gif`)"
                      alt="trending"
                      height="120px"
                      width="auto"
                  /></button>
                <label style="font-weight: bold ;white-space: nowrap ;margin-left: 10px  "> Nöbetçi Eczaneler</label>

              </div>

            </div>


            <div class=" col-lg-12" style="align-items: start;background-color: transparent">
              <button style="border: 1px ; background-color: transparent"
                      @click="clickHrefMethod('https://mvc.aeo.org.tr/Public/EczaneAra')">
                <img :src="require(`@/assets/images/kartbastır.png`)"
                     alt="trending"
                     height="144px"
                     style="margin-left: -12px"
                     width="auto"
                />
              </button>
              <label style="font-weight: bold ;white-space: nowrap;background-color: transparent "> Nöbet Kartı
                Bastır</label>

            </div>


          </div>
        </div>

      </div>
      <div v-if="smallAnnouncementGallery!==null" class="  row " style="margin-left: 2px; width: 100%">
        <h5 class="text-secondary" style="font-family: Calibri ; font-size: 30px ; font-weight: 700; ">DUYURULAR
          <!--          <router-link class=" colorful-link3 p-2 mb-2" tag="div" to="/allposts/:name/:id">-->
          <!--            Tüm duyurular-->
          <!--          </router-link>-->
        </h5>
      </div>
      <div v-if="smallAnnouncementGallery!==null" class="">
        <div class="row mx-auto ">
          <div class="col-lg-3">
            <div class="">
              <home-one
                  :ad="false"
                  :darkClass="darkClass"
                  :datas="smallAnnouncementGallery.slice(0,1 )"
                  :sharePost="false"
                  :signup="false"
                  :trendingBigPost="false"
                  :trendingShortPost="false"
                  role="sidebar"

              />
            </div>
          </div>
          <div class="col-lg-3">
            <div class="">
              <home-one
                  :ad="false"
                  :darkClass="darkClass"
                  :datas="smallAnnouncementGallery.slice(1, 2)"
                  :sharePost="false"
                  :signup="false"
                  :trendingBigPost="false"
                  :trendingShortPost="false"
                  role="sidebar"

              />
            </div>
          </div>
          <div class="col-lg-3">
            <div class="">
              <home-one
                  :ad="false"
                  :darkClass="darkClass"
                  :datas="smallAnnouncementGallery.slice(2, 3)"
                  :sharePost="false"
                  :signup="false"
                  :trendingBigPost="false"
                  :trendingShortPost="false"
                  role="sidebar"

              />
            </div>
          </div>
          <div class="col-lg-3">
            <div class="">
              <home-one
                  :ad="false"
                  :darkClass="darkClass"
                  :datas="smallAnnouncementGallery.slice(3, 4)"
                  :sharePost="false"
                  :signup="false"
                  :trendingBigPost="false"
                  :trendingShortPost="false"
                  role="sidebar"

              />
            </div>
          </div>

        </div>
        <div class="row mx-auto ">
          <div class="col-lg-3">
            <div class="">
              <home-one
                  :ad="false"
                  :darkClass="darkClass"
                  :datas="smallAnnouncementGallery.slice(4,5 )"
                  :sharePost="false"
                  :signup="false"
                  :trendingBigPost="false"
                  :trendingShortPost="false"
                  role="sidebar"

              />
            </div>
          </div>
          <div class="col-lg-3">
            <div class="">
              <home-one
                  :ad="false"
                  :darkClass="darkClass"
                  :datas="smallAnnouncementGallery.slice(5, 6)"
                  :sharePost="false"
                  :signup="false"
                  :trendingBigPost="false"
                  :trendingShortPost="false"
                  role="sidebar"

              />
            </div>
          </div>
          <div class="col-lg-3">
            <div class="">
              <home-one
                  :ad="false"
                  :darkClass="darkClass"
                  :datas="smallAnnouncementGallery.slice(6, 7)"
                  :sharePost="false"
                  :signup="false"
                  :trendingBigPost="false"
                  :trendingShortPost="false"
                  role="sidebar"

              />
            </div>
          </div>
          <div class="col-lg-3">
            <div class="">
              <home-one
                  :ad="false"
                  :darkClass="darkClass"
                  :datas="smallAnnouncementGallery.slice(7, 8)"
                  :sharePost="false"
                  :signup="false"
                  :trendingBigPost="false"
                  :trendingShortPost="false"
                  role="sidebar"

              />
            </div>
          </div>

        </div>
      </div>

      <div class="row">
        <div class="col-lg-1">
          <button class="butonileri fal fa-angle-left" style="color: white" @click="slickprev"></button>
        </div>
        <div class="col-lg-10">
          <VueSlickCarousel v-if="this.subjectTypesCount!==null" ref="carosel" :arrows="true" :dots="true"
                            v-bind="trendingSettings">


            <button   class="px-2 butonslider"
                    @click="clickRouteMethod(`/allposts/${subjectTypesCount[0].text}/${subjectTypesCount[0].id}`)">

              <img :src="require(`@/assets/images/slider/${image[0].name}`)" alt="trending" class="imgslick"/>
              <div

                  class="fw-bold text-secondary"
              >
                {{ subjectTypesCount[0].text }}
              </div>
            </button>


            <button class="px-2 butonslider"
                    @click="clickRouteMethod(`/allposts/${subjectTypesCount[6].text}/${subjectTypesCount[6].id}` )">

              <img :src="require(`@/assets/images/slider/${image[4].name}`)" alt="trending" class="imgslick"/>
              <div

                  class="fw-bold text-secondary"
              >
                {{ subjectTypesCount[6].text }}
              </div>

            </button>
            <button  class="px-2 butonslider" v-if="subjectTypesCount[8] && subjectTypesCount[8].text">

              <img :src="require(`@/assets/images/slider/${image[5].name}`)"
                   alt="trending"
                   class="imgslick"
                   @click="clickRouteMethod(`/allposts/${subjectTypesCount[8].text}/${subjectTypesCount[8].id}` )"/>
              <div

                  class="fw-bold text-secondary"
              >
                {{ subjectTypesCount[8].text }}
              </div>


            </button>
                        <button class="px-2 butonslider" v-if="subjectTypesCount[9] && subjectTypesCount[9].text">

              <img :src="require(`@/assets/images/slider/${image[6].name}`)"
                   alt="trending"
                   class="imgslick"
                   @click="clickRouteMethod(`/allposts/${subjectTypesCount[9].text}/${subjectTypesCount[9].id}` )"/>
              <div

                  class="fw-bold text-secondary"
              >
                {{ subjectTypesCount[9].text }}
              </div>


            </button>
            <button class="px-2 butonslider" v-if="subjectTypesCount[12] && subjectTypesCount[12].text">

              <img :src="require(`@/assets/images/slider/${image[7].name}`)"
                   alt="trending"
                   class="imgslick"
                   @click="clickRouteMethod(`/allposts/${subjectTypesCount[12].text}/${subjectTypesCount[12].id}` )"/>

              <div

                  class="fw-bold text-secondary"
              >
                {{ subjectTypesCount[12].text }}
              </div>

            </button> 
            <button class="px-2 butonslider">

              <img :src="require(`@/assets/images/slider/${image[2].name}`)" alt="trending"
                   class="imgslick" @click="clickRouteMethod(`/allposts/TSK/33` )"/>
              <div

                  class="fw-bold text-secondary"
              >
                TSK
              </div>

            </button>

          </VueSlickCarousel>
        </div>
        <div class="col-lg-1">
          <button class="butongeri fal fa-angle-right " style="color: white" @click="slicknext"></button>
        </div>
        <!--buton isimleri ters-->


      </div>      <div class="row" style="margin-top: 100px">

      <div class="col-lg-12" >
        <VueSlickCarousel v-if="this.subjectTypesCount!==null" :arrows="true" :dots="true"
                          v-bind="trendingSettings">


          <button   class="px-2 katalogSlider"
                    @click="clickRouteMethod(`/allposts/${subjectTypesCount[0].text}/${subjectTypesCount[0].id}`)">


          </button>
          <button class="px-2 katalogSlider" @click="downloadPDF">
            <img :src="require(`@/assets/images/WhatsApp Görsel 2024-07-12 saat 13.32.18_5dcf1a8a.jpg`)" alt="trending" class="imgCatalog" />
          </button>



        </VueSlickCarousel>
      </div>

      <!--buton isimleri ters-->


    </div>


      <!-- Right Side end-->
      <div
          v-if="
            smallAnnouncementGallery != null &&
            this.$store.state.announcementModule.subjectTypes != null
          "
          class="row container mx-auto mb-5 p-0"
      >
      </div>

      <div v-else class="card-body">
        <Spinner></Spinner>
      </div>

    </div>

  </div>


</template>


<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import 'vue-glide-js/dist/vue-glide.css'
import AnnouncementCategory from "../Data/AnnouncementCategory";
import Slider from "../Helpers/Slider.vue";
import {mapActions, mapGetters} from "vuex";
import moment from "moment";
import Spinner from "../Spinner/HollowDotsSpinner/Spinner.vue";
import doz from "../Data/DOZ.json";
import advertisement from "../Data/Advertisement.json";
import BigVideoCardWithDes from "@/components/Utility/Cards/BigVideoCardWithDes.vue";
import HomeOne from "../Utility/Sidebar/StyleOne.vue";


export default {
  components: {

    BigVideoCardWithDes, Slider, Spinner, HomeOne, VueSlickCarousel


  },
  props: {
    darkClass: {
      type: Object,
      default: () => {
      },
    },
  },
  data: () => ({
    image: [{name: 'yonetim.jpeg'}, {name: 'sgk.png'}, {name: 'tsk.jpeg'}, {name: 'sgk.png'}, {name: 'sgk.png'}, {name: 'teb.png'}, {name: 'titck.png'}, {name: 'digerImage.jpeg'}, {name: 'tsk.jpeg'}, {name: 'yonetim.jpeg'}

    ],
    trendingSettings: {
      "arrows": true,
      "dotsClass": "slick-dots custom-dot-class",
      "edgeFriction": 0.35,
      "infinite": false,
      "speed": 500,
      "slidesToShow": 3,
      "slidesToScroll": 1,
      "touchThreshold": 3
    },
    loadingComplete: false, // Loading durumu
    advertisement: advertisement.data,
    doz: doz.data,
    AnnouncementCategory,
    sliderGallery: [],
    smallAnnouncementGallery: null,
    announcementFromPresident: null,
    subjectTypesCount: null,
    selectedGallery: "trendy",
    miniCardsSettings: {
      slidesToShow: 8,
      slideToScroll: 1,
      autoplay: true,
      arrows: false,
      responsive: [
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
            slideToScroll: 1,
            autoplay: true,
          },
        },
      ],
    },
    BigCardsSettings: {
      slideToScroll: 1,
      slidesPerView: 2,
      autoplay: true,
      arrows: false,
    },
    SliderSettings: {
      slidesToShow: 1,
      slideToScroll: 1,
      autoplay: true,
      arrows: false,
    },
  }),
  computed: {
    chunkedSubjectTypesCount() {
      const resultArray = [];
      for (let i = 0; i < this.subjectTypesCount.length; i += this.itemsPerSlide) {
        resultArray.push(this.subjectTypesCount.slice(i, i + this.itemsPerSlide));
      }
      return resultArray;
    },
  },

  methods: {
    ...mapActions("announcementModule", [
      "getShift",
      "getTodaysNews",
      "getSubjectTypes",
      "getPublishPlaces",
      "getSubjectTypeCount",
      "getImageGalleryById"
    ]),
    ...mapGetters("announcementModule", [
      "getBaskandan",
      "getterPublishPlaces",
      "getSliderGallery",
      "getDuyurular",
      "getterSubjectTypesCount",
      "getSliderGallery",
    ]),
    downloadPDF() {
      const link = document.createElement('a');
      link.href = 'Doz_Dergi_33_Temmuz_2024 - Kopya.pdf'; // PDF dosyasının yolu
      link.download = 'Doz.pdf'; // İndirilen dosyanın adı
      link.click();
    },
    postGalleryPrev() {
      this.$refs.BigCards.prev();

    },
    postsliderPrev() {
      this.$refs.subjectTypesCount.prev();

    },
    slickprev() {
      this.$refs.carosel.prev();
    },
    slicknext() {
      this.$refs.carosel.next();
    },

    clickRouteMethod(route) {
      window.open(route, '_blank');  // Yeni sekmede aç
      setTimeout(() => {
        this.$router.push(route);  // Mevcut sayfada yönlendir
      }, 500);  // 500ms gecikme
    },
    clickHrefMethod(href) {
      window.open(href, "_blank");
    },
    postGalleryNext() {
      this.$refs.BigCards.next();

    },

    format_date(value) {
      return moment(value).format("DD.MM.YYYY");
    },

    async fetchData() {
      if (this.getterSubjectTypesCount().length === 0) {
        await this.getSubjectTypeCount();
        console.log("empty subject type")
      }
      this.subjectTypesCount = this.getterSubjectTypesCount();
      console.log(this.subjectTypesCount)

      if (this.$store.state.announcementModule.publishPlaces.length === 0) {
        await this.getPublishPlaces();
      }
      if (this.$store.state.announcementModule.subjectTypes.length === 0) {
        await this.getSubjectTypes();
      }

      this.announcementFromPresident = this.getBaskandan();
      this.smallAnnouncementGallery = this.getDuyurular();
      
      this.loadingComplete = true;

    },
    async fetchSlider() {
      let publishPlaceIds = [20, 23, 48, 27];
      let result = await this.getTodaysNews(publishPlaceIds);

      if (result.data && result.data.length > 0) {
        this.sliderGallery = this.getSliderGallery().slice(0, 10);
      } else {
        this.sliderGallery = [];
      }
    }
  },
  created() {
    this.fetchSlider();
    this.fetchData();
  },
  watch: {
    "$store.state.announcementModule.todaysNews": function () {
      this.smallAnnouncementGallery = this.getDuyurular();
      this.announcementFromPresident = this.getBaskandan();
      this.sliderGallery = this.getSliderGallery();
    },
  },
};
</script>

<style>
.topsliderright {
  width: 30px;
  z-index: 999;
  background-color: #ffffff;
  height: 30px;
  position: absolute;
  display: flex;
  margin-top: 60px;
  margin-left: 60px;
  border-radius: 20px;
  border: none;
}

.topsliderleft:hover{
  background-color: #f60a21;
  color: white;
}

.topsliderright:hover{
  background-color: #f60a21;
  color: white;
}
.topsliderleft {
  width: 30px;
  z-index: 999;
  background-color: #ffffff;
  height: 30px;
  position: absolute;
  display: flex;
  margin-top: 60px;
  margin-left: 10px;
  border-radius: 20px;
  border: none;
}

.butonslider {
  border: none;
  background-color: transparent;
}

.imgslick {
  width: 500px;
  height: 210px;
}

.eski {
  border: none;
  background-color: #c51e2f;
  margin-left: 0.5vw;
  height: 4.5vh;
  color: white;
  width: 8vw;
  padding-bottom: 4px;
  font-size: 25px;
  justify-content: center;
  display: flex;
  text-align: center;
}


.butonileri {
  margin-top: 80px;
  margin-left: 40px;
  background-color: #c51e2f;
  border-radius: 10px;
  border: none;
  width: 40px;
  height: 30px;
  position: absolute;



}

.butongeri {
  background-color: #c51e2f;
  position: absolute;

  border-radius: 10px;
  border: none;
  width: 40px;
  height: 30px;
  margin-top: 80px;
}








.yazı3 {
  white-space: nowrap;
  margin-top: 5vh;
  margin-left: 3vw;
  font-size: 1.5em;
  color: black;
}

.box {
  width: 15vw;
  background-color: white;
  border: none;


}

.cheatbutton {
  width: 80%;
  border: none;
  height: 70px;
  margin-top: 60px;
  font-weight: bold;
  background-color: #fff200;
  color: #000000;
}

  @media (max-width: 1191px) {
   .kapsama{
     margin-top: 50px;
   }
.imgslick{
  width: 500px;
  height: 110px;

}
  }

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

.loading svg polyline {
  fill: none;
  stroke-width: 3;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.loading svg polyline#back {
  fill: none;
  stroke: #ff4d5033;
}

.loading svg polyline#front {
  fill: none;
  stroke: #ff4d4f;
  stroke-dasharray: 48, 144;
  stroke-dashoffset: 192;
  animation: dash_682 1.4s linear infinite;
}

@keyframes dash_682 {
  72.5% {
    opacity: 0;
  }

  to {
    stroke-dashoffset: 0;
  }
}


.colorful-leftside div {
  cursor: pointer;
}

.ortalayıcı {
  position: relative;
}


.colorful-link3 {
  background: linear-gradient(to right, #c51e2f 10%, white 100%);;
  color: white;

}

.colorful-link3:hover {
  background: linear-gradient(to right, #c51e2f 10%, #c51e2f 100%);;
  color: white;
}


div.baskandan {
  text-align: left;
}


.card-footer a {
  color: #c51e2f;
  font-weight: bold;
}

.card-img img {
  width: 100%;
}


@media (max-width: 992px) {
}

@media (max-width: 992px) {
  .card slider {
    width: 100%;
  }
}


.post_gallery_slider {
  position: relative;
}

.post_gallery_inner_slider {
  position: absolute;
  top: 150px;
  right: 0;
  height: 10px;
  margin-top: 0;
}

@media (max-width: 576px) {
  .post_gallery_inner_slider {
    display: none;
  }
}

/* Büyük ekranlarda galeri slider'ının pozisyonu */
@media (min-width: 576px) {
  .post_gallery_inner_slider {
    top: 150px;
  }
}

@media (min-width: 768px) {
  .post_gallery_inner_slider {
    top: 150px;
  }
}

@media (min-width: 992px) {
  .post_gallery_inner_slider {
    top: 250px;
  }
}

@media (min-width: 1200px) {
  .post_gallery_inner_slider {
    top: 180px;
    height: 20px;
  }
}

/* Kare resim kutularının boyutları */


/* Tüm attırıcı container için pozisyon ve stiller */
.all-att {
  position: absolute;
  right: 0;
  top: 0;
  width: 60px;
  text-align: center;
  border-radius: 0 0 0 10px;
}


.colorful-link3 {
  background-color: #ecfdff;;
  font-size: 20px;
  font-weight: 700;
  border: 1px;
  border-radius: 0;
  font-family: Bahnschrift SemiBold;
}

.colorful-link3:hover {
  background-color: #ec0b22;
  color: white;
}

.post_gallery_inner_slider .slick-arrow {
  position: absolute;
  color: #e00000;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  text-align: center;
  border-radius: 10px;
  background: #f8f6f6;
  height: 40px;
  cursor: pointer;
  box-shadow: 0px 10px 40px rgba(23, 34, 43, 0.1);
  transition: all 0.3s ease-out 0s;
}

.arrow-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.katalogSlider {

  border: none;
  background-color: transparent;
}

.imgCatalog {
  width: 701px;
  height: 496px;
}
</style>